import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { EmployeeService } from '../../../../features/employees/services/employee.service';

@Component({
  selector: 'app-branch-access',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './branch-access.component.html',
  styleUrl: '../../add-employee.component.scss',
})
export class BranchAccessComponent implements OnInit {
  branchAccessForm: FormGroup;
  public userRolesData: any[] = [];
  public branchData: any[] = [];

  @Output() formSubmit = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private employeeService: EmployeeService
  ) {
    this.branchAccessForm = this.fb.group({
      userRole: ['', Validators.required],
      branchName: ['', Validators.required],
      userName: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.loadUserRoles();
    this.loadBranches();
  }

  private loadUserRoles() {
    this.employeeService.getUserRoles().subscribe({
      next: (userRoles) => {
        this.userRolesData = userRoles;
      },
      error: (error) => console.error(error),
    });
  }

  private loadBranches() {
    this.employeeService.getBranches().subscribe({
      next: (branches) => {
        this.branchData = branches;
      },
      error: (error) => console.error(error),
    });
  }

  onNext(): void {
    if (this.branchAccessForm.valid) {
      console.log('Document Info------', this.branchAccessForm.value);
      this.formSubmit.emit(this.branchAccessForm.value);
    } else {
      console.log('Form is Invalid');
    }
  }

  onCancel(): void {
    this.branchAccessForm.reset();
  }
}
