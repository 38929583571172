import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-salary-details',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './salary-details.component.html',
  styleUrl: '../../add-employee.component.scss',
})
export class SalaryDetailsComponent {
  salaryDetailForm: FormGroup;

  @Output() formSubmit = new EventEmitter<any>();

  constructor(private fb: FormBuilder) {
    this.salaryDetailForm = this.fb.group({
      annualPay: ['', Validators.required],
      housingAllowance: ['', Validators.required],
      transportAllowance: ['', Validators.required],
      medicalInsurance: ['', Validators.required],
      overTimePay: ['', Validators.required],
      otherAllowances: ['', Validators.required],
    });
  }

  onNext(): void {
    if (this.salaryDetailForm.valid) {
      console.log('Salary Info--------', this.salaryDetailForm.value);
      this.formSubmit.emit(this.salaryDetailForm.value);
    } else {
      console.log('Form is Invalid');
    }
  }

  onCancel(): void {
    this.salaryDetailForm.reset();
  }
}
