<app-layout>
  <section class="cmn-innerpage-wrapper add-employee">
    <div class="employee-tab-wrapper">
      <mat-tab-group animationDuration="0ms">
        <!-- Mat Tab One -->
        <mat-tab>
          <ng-template mat-tab-label>
            <h2 class="tab-1">Personal Info</h2>
          </ng-template>
          <app-personal-info
            (formSubmit)="collectPersonalInfo($event)"
          ></app-personal-info>
        </mat-tab>
        <!-- Mat Tab Two -->
        <mat-tab>
          <ng-template mat-tab-label>
            <h2 class="tab-2">Professional Info</h2>
          </ng-template>
          <app-professional-info
            (formSubmit)="collectProfessionalInfo($event)"
          ></app-professional-info>
        </mat-tab>
        <!-- Mat Tab Three -->
        <mat-tab>
          <ng-template mat-tab-label>
            <h2 class="tab-3">Documents</h2>
          </ng-template>
          <app-documents
            (formSubmit)="collectDocuments($event)"
          ></app-documents>
        </mat-tab>
        <!-- Mat Tab Four -->
        <mat-tab>
          <ng-template mat-tab-label>
            <h2 class="tab-4">Branch Access</h2>
          </ng-template>
          <app-branch-access
            (formSubmit)="collectBranchAccess($event)"
          ></app-branch-access>
        </mat-tab>
        <!-- Mat Tab Five -->
        <mat-tab>
          <ng-template mat-tab-label>
            <h2 class="tab-5">Salary Details</h2>
          </ng-template>
          <app-salary-details
            (formSubmit)="collectSalaryDetails($event)"
          ></app-salary-details>
        </mat-tab>
      </mat-tab-group>
    </div>
  </section>
</app-layout>
