import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GET_BRANCHES } from '../graphql/querries/branch.query';
import { CREATE_BRANCH } from '../graphql/mutation/branch.mutation';

@Injectable({
  providedIn: 'root',
})
export class BranchService {
  constructor(private apollo: Apollo) {}

  fetchBranches(search: string = ''): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_BRANCHES,
        variables: { search },
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(map((result) => result.data?.branches || []));
  }

  createBranch(branchData: any): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: CREATE_BRANCH,
      variables: {
        input: branchData,
      },
    });
  }
}
