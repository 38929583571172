// login.component.ts
import { Component, OnInit, signal } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../core/services/auth.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    RouterModule,
    ReactiveFormsModule,
    CommonModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading: boolean = false;
  hide = signal(true);

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private authService: AuthService
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      rememberMe: [false],
    });
  }

  ngOnInit(): void {
    this.rememberMeCredentials();
    this.autoLogin();
    this.setupLogoutListener();
  }

  login() {
    if (this.loginForm.valid) {
      this.loading = true;
      const { email, password, rememberMe } = this.loginForm.value;
      console.log('Remember Me:', rememberMe);

      this.authService.login(email, password).subscribe({
        next: (response) => {
          if (response.access_token) {
            if (rememberMe) {
              localStorage.setItem('AUTH_TOKEN', response.access_token);
              localStorage.setItem('email', email);
              localStorage.setItem('rememberMe', 'true');
            } else {
              sessionStorage.setItem('AUTH_TOKEN', response.access_token);
              localStorage.removeItem('email');
              localStorage.removeItem('rememberMe');
            }

            this.toastr.success(response.message || 'Login Successful');
            this.router.navigate(['/dashboard']);
          }
          this.loading = false;
        },
        error: (error) => {
          this.toastr.error(error.message || 'Login Failed', 'Error');
          this.loading = false;
        },
      });
    // } else {
    //   this.toastr.warning('Please fill out the form correctly', 'Warning');
    }
  }

  logout() {
    localStorage.removeItem('AUTH_TOKEN');
    sessionStorage.removeItem('AUTH_TOKEN');
    localStorage.setItem('logout', 'true');
    this.router.navigate(['/login']);
  }

  clickEvent(event: MouseEvent) {
    this.hide.set(!this.hide());
    event.stopPropagation();
  }

  private rememberMeCredentials() {
    const rememberMe = localStorage.getItem('rememberMe');
    const email = localStorage.getItem('email');
    if (rememberMe === 'true' && email) {
      this.loginForm.patchValue({ email, rememberMe: true });
    } else {
      this.loginForm.patchValue({ rememberMe: false });
    }
  }

  private autoLogin() {
    const token =
      localStorage.getItem('AUTH_TOKEN') ||
      sessionStorage.getItem('AUTH_TOKEN');
    if (token) {
      this.router.navigate(['/dashboard']);
    }
  }

  private setupLogoutListener() {
    window.addEventListener('storage', (event) => {
      if (event.key === 'logout') {
        this.router.navigate(['/login']);
      }
    });
  }
}
