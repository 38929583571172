import { gql } from 'apollo-angular';

export const CREATE_EMPLOYEE = gql`
  mutation createEmployee($input: CreateEmployeeInput!) {
    createEmployee(createEmployeeInput: $input) {
      id
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      email
      phoneNumber
      alternatePhoneNumber
      address
      permanentAddress
      dateOfJoining
      employmentType
      employeeType
      employeeStatus
      profilePicture
      maritalStatus
      nationality
      passportNumber
      visaType
      visaIssueDate
      visaExpiryDate
      salary
      bankName
      bankAccountNumber
      IFSCCode
      departmentId
      designationId
      designation {
        id
        designationName
        status
        createdAt
        updatedAt
        deletedAt
      }
      reportingManagerId
      branchId
      branch {
        id
        branchName
        address
        city
        state
        country
        zipCode
        phoneNumber
        branchCode
        isHeadOffice
        email
        createdAt
        updatedAt
        deletedAt
      }
      employeeDocuments {
        id
        employeeId
        documentType
        documentNumber
        issueDate
        expiryDate
        issuingAuthority
        filePath
        isVerified
        remarks
      }
      employeeSalaries {
        id
        basicSalary
        hra
        allowances
        deductions
        grossSalary
        netSalary
        remarks
      }
    }
  }
`;
