<div class="tab-content-wrapper">
  <form [formGroup]="personalInfoForm" (ngSubmit)="onNext()">
    <div class="image-upload">
      <div class="upload-box">
        <input
          #fileInput
          type="file"
          (change)="onFileSelected($event)"
          style="display: none"
        />
        <!-- only add "uploaded" class to button if image is uploaded -->
        <button (click)="fileInput.click()" class="uploaded">
          <div class="img-wrapper">
            <!-- uploaded Image -->
            <!-- {{profilePhoto?.name}} -->
            <img src="assets/images/profile.png" />
          </div>
          <span class="photo-icon"></span>
        </button>
      </div>
    </div>
    <div class="employee-form-wrapper">
      <div class="form-wrapper">
        <label for="">First Name</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter First Name"
            formControlName="firstName"
          />
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Last Name</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Last Name"
            formControlName="lastName"
          />
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Mobile Number</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Mobile Number"
            formControlName="mobileNumber"
          />
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Email Address</label>
        <mat-form-field>
          <input matInput placeholder="Email Address" formControlName="email" />
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Date of Birth</label>
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="picker"
            formControlName="dateOfBirth"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Marital Status</label>
        <mat-form-field>
          <mat-select
            placeholder="Select Marital Status"
            formControlName="martialStatus"
          >
            <mat-option value="Single">Single</mat-option>
            <mat-option value="Married">Married</mat-option>
            <mat-option value="Divorced">Divorced</mat-option>
            <mat-option value="Widowed">Widowed</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Gender</label>
        <mat-form-field>
          <mat-select placeholder="Select Gender" formControlName="gender">
            <mat-option value="Male">Male</mat-option>
            <mat-option value="Female">Female</mat-option>
            <mat-option value="Others">Others</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Nationality</label>
        <mat-form-field>
          <mat-select
            placeholder="Select nationality"
            formControlName="nationality"
          >
            @for (country of countries; track country) {
            <mat-option [value]="country.name">{{ country.name }}</mat-option>
            }
            <!-- <mat-option value="option1">Option 1</mat-option>
            <mat-option value="option2">Option 2</mat-option>
            <mat-option value="option3">Option 3</mat-option> -->
          </mat-select>
          <mat-error
            *ngIf="
              personalInfoForm.get('nationality')!.touched &&
              personalInfoForm.get('nationality')!.hasError('required')
            "
            >*Country is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Address</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Address"
            formControlName="address"
          />
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">City</label>
        <mat-form-field>
          <input matInput placeholder="Enter City" formControlName="city" />
          <!-- <mat-select placeholder="Select City" formControlName="city">
            <mat-option value="option1">Option 1</mat-option>
            <mat-option value="option2">Option 2</mat-option>
            <mat-option value="option3">Option 3</mat-option>
          </mat-select> -->
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">State</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter State"
            formControlName="state"
          />
          <!-- <mat-select placeholder="Select State" formControlName="state">
            <mat-option value="option1">Option 1</mat-option>
            <mat-option value="option2">Option 2</mat-option>
            <mat-option value="option3">Option 3</mat-option>
          </mat-select> -->
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">ZIP Code</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter ZIP Code"
            formControlName="zipcode"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="submit-btn-wrapper">
      <button class="cmn-cancel-btn" (click)="onCancel()">Cancel</button>
      <button class="cmn-next-btn" type="submit">Next</button>
    </div>
  </form>
</div>
