import { EmployeeService } from './../../../../features/employees/services/employee.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-professional-info',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './professional-info.component.html',
  styleUrl: '../../add-employee.component.scss',
})
export class ProfessionalInfoComponent implements OnInit {
  professionalInfoForm: FormGroup;
  public designationData: any[] = [];
  public employeeTypeData: any[] = [];

  @Output() formSubmit = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private employeeService: EmployeeService
  ) {
    this.professionalInfoForm = this.fb.group({
      employeeId: ['', Validators.required],
      designation: ['', Validators.required],
      employeeType: ['', Validators.required],
      joiningDate: ['', Validators.required],
      currentCTC: ['', Validators.required],
      officeLocation: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.loadDesignationData();
    this.loadEmployeeTypeData();
  }

  private loadDesignationData() {
    this.employeeService.getDesignations().subscribe({
      next: (designations) => {
        this.designationData = designations;
      },
      error: (error) => console.error(error),
    });
  }

  private loadEmployeeTypeData() {
    this.employeeService.getEmployeeTypes().subscribe({
      next: (employeeTypes) => {
        this.employeeTypeData = employeeTypes;
      },
      error: (error) => console.error(error),
    });
  }

  onNext(): void {
    if (this.professionalInfoForm.valid) {
      console.log('professional Info-------', this.professionalInfoForm.value);
      this.formSubmit.emit(this.professionalInfoForm.value);
    } else {
      console.log('Form is Invalid');
    }
  }

  onCancel(): void {
    this.professionalInfoForm.reset();
  }
}
