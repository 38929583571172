import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PersonalInfoComponent } from './components/personal-info/personal-info.component';
import { ProfessionalInfoComponent } from './components/professional-info/professional-info.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { BranchAccessComponent } from './components/branch-access/branch-access.component';
import { SalaryDetailsComponent } from './components/salary-details/salary-details.component';
import { EmployeeService } from '../../features/employees/services/employee.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-employee',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatTabsModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    PersonalInfoComponent,
    ProfessionalInfoComponent,
    DocumentsComponent,
    BranchAccessComponent,
    SalaryDetailsComponent,
  ],
  templateUrl: './add-employee.component.html',
  styleUrl: './add-employee.component.scss',
})
export class AddEmployeeComponent {
  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;
  employeeData: any = {};

  constructor(
    private employeeService: EmployeeService,
    private toastr: ToastrService
  ) {}

  collectPersonalInfo(data: any) {
    this.employeeData.personalInfo = data;
    console.log(
      'Collected Personal Data--------',
      this.employeeData.personalInfo
    );
    this.tabGroup.selectedIndex = 1;
  }

  collectProfessionalInfo(data: any) {
    this.employeeData.professionalInfo = data;
    console.log(
      'Collected professional data------',
      this.employeeData.professionalInfo
    );
    this.tabGroup.selectedIndex = 2;
  }

  collectDocuments(data: any) {
    this.employeeData.documents = data;
    console.log('Collected documents data-----', this.employeeData.documents);
    this.tabGroup.selectedIndex = 3;
  }

  collectBranchAccess(data: any) {
    this.employeeData.branchAccess = data;
    console.log(
      'Collected branch access data-------',
      this.employeeData.branchAccess
    );
    this.tabGroup.selectedIndex = 4;
  }

  collectSalaryDetails(data: any) {
    this.employeeData.salaryDetails = data;
    console.log(
      'Collected salary data-------',
      this.employeeData.salaryDetails
    );
    this.submitEmployeeData();
  }

  submitEmployeeData() {
    this.employeeService.createEmployee(this.employeeData).subscribe({
      next: (response) => {
        console.log('Employee created successfully:', response);
        this.toastr.success('Employee created successfully');
      },
      error: (error) => {
        console.error('Error creating employee:', error);
        this.toastr.error(error);
      },
    });
  }
}
