<div class="tab-content-wrapper">
  <form [formGroup]="professionalInfoForm" (ngSubmit)="onNext()">
    <div class="employee-form-wrapper">
      <div class="form-wrapper">
        <label for="">Employee ID</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Employee ID"
            formControlName="employeeId"
          />
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Designation</label>
        <mat-form-field>
          <mat-select
            placeholder="Select Designation"
            formControlName="designation"
          >
            <mat-option
              *ngFor="let designation of designationData"
              [value]="designation.id"
            >
              {{ designation.designationName }}
            </mat-option>
            <!-- <mat-option value="option1">Option 1</mat-option>
            <mat-option value="option2">Option 2</mat-option>
            <mat-option value="option3">Option 3</mat-option> -->
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Select Employee Type</label>
        <mat-form-field>
          <mat-select
            placeholder="Select Employee Type"
            formControlName="employeeType"
          >
            <mat-option
              *ngFor="let empType of employeeTypeData"
              [value]="empType.id"
            >
              {{ empType.employeeType }}
            </mat-option>
            <!-- <mat-option value="option1">Option 1</mat-option>
            <mat-option value="option2">Option 2</mat-option>
            <mat-option value="option3">Option 3</mat-option> -->
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Select Joining Date</label>
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="picker2"
            formControlName="joiningDate"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Current CTC</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Current CTC"
            formControlName="currentCTC"
          />
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Office Address</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Office Address"
            formControlName="officeLocation"
          />
          <!-- <mat-datepicker-toggle
            matIconSuffix
            [for]="picker3"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker3></mat-datepicker> -->
        </mat-form-field>
      </div>
    </div>
    <div class="submit-btn-wrapper">
      <button class="cmn-cancel-btn" (click)="onCancel()">Cancel</button>
      <button class="cmn-next-btn" type="submit">Next</button>
    </div>
  </form>
</div>
