<div class="tab-content-wrapper">
  <form [formGroup]="documentForm" (ngSubmit)="onNext()">
    <div class="employee-form-wrapper">
      <div class="form-wrapper">
        <label for="">Visa Number</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Visa Number"
            formControlName="visaNumber"
          />
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Passport Number</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Passport Number"
            formControlName="passportNumber"
          />
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Bank Name</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Bank Name"
            formControlName="bankName"
          />
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Account No.</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Account No."
            formControlName="accountNumber"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="submit-btn-wrapper">
      <button class="cmn-cancel-btn" (click)="onCancel()">Cancel</button>
      <button class="cmn-next-btn" type="submit">Next</button>
    </div>
  </form>
</div>
