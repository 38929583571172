<div class="tab-content-wrapper">
  <form [formGroup]="branchAccessForm" (ngSubmit)="onNext()">
    <div class="employee-form-wrapper">
      <div class="form-wrapper">
        <label for="">Select User Role</label>
        <mat-form-field>
          <mat-select placeholder="Select User Role" formControlName="userRole">
            <mat-option *ngFor="let role of userRolesData" [value]="role.id">
              {{ role.profileName }}
            </mat-option>
            <!-- <mat-option value="option1">Option 1</mat-option>
              <mat-option value="option2">Option 2</mat-option>
              <mat-option value="option3">Option 3</mat-option> -->
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Select Branch</label>
        <mat-form-field>
          <mat-select
            placeholder="Select Select Branch"
            formControlName="branchName"
          >
            <mat-option *ngFor="let branch of branchData" [value]="branch.id">
              {{ branch.branchName }}
            </mat-option>
            <!-- <mat-option value="option1">Option 1</mat-option>
              <mat-option value="option2">Option 2</mat-option>
              <mat-option value="option3">Option 3</mat-option> -->
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Username</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Username"
            formControlName="userName"
          />
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Password</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Password"
            formControlName="password"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="submit-btn-wrapper">
      <button class="cmn-cancel-btn" (click)="onCancel()">Cancel</button>
      <button class="cmn-next-btn" type="submit">Next</button>
    </div>
  </form>
</div>
