import { AbstractControl, ValidatorFn } from '@angular/forms';

// Validator for UAE phone number
export function uaePhoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid = /^(\+971|0)?5\d{8}$/.test(control.value);
    return valid ? null : { invalidPhoneNumber: { value: control.value } };
  };
}

// Validator for zip code to ensure it's all digits
export function numericValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid = /^\d+$/.test(control.value);
    return valid ? null : { invalidZipCode: { value: control.value } };
  };
}

// Validator to ensure only digits are allowed in the phone number field
export function phoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid = /^\d+$/.test(control.value); // Only digits allowed
    return valid ? null : { invalidPhoneNumber: { value: control.value } };
  };
}