import { Component, EventEmitter, Output, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-personal-info',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './personal-info.component.html',
  styleUrl: '../../add-employee.component.scss',
})
export class PersonalInfoComponent {
  profilePhoto: any = null;
  personalInfoForm: FormGroup;
  countries: any[] = [];

  @Output() formSubmit = new EventEmitter<any>();

  constructor(private fb: FormBuilder, private http: HttpClient) {
    this.personalInfoForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: [''],
      mobileNumber: [''],
      email: ['', [Validators.required, Validators.email]],
      dateOfBirth: [''],
      martialStatus: [''],
      gender: [''],
      nationality: [''],
      address: [''],
      city: [''],
      state: [''],
      zipcode: [''],
    });
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      // Handle the file, e.g., upload it or read it
      console.log('Selected file:', file);
      this.profilePhoto = file;
    }
  }

  ngOnInit(): void {
    this.loadCountries();
  }

  loadCountries(): void {
    this.http.get<any[]>('assets/countries.json').subscribe(
      (data) => {
        this.countries = data;
      },
      (error) => {
        console.log('Error loading country data----', error);
      }
    );
  }

  onNext(): void {
    if (this.personalInfoForm.valid) {
      console.log('personal Info-----', this.personalInfoForm.value);
      this.formSubmit.emit(this.personalInfoForm.value);
    } else {
      console.log('Form is Invalid');
    }
  }

  onCancel(): void {
    this.personalInfoForm.reset();
  }
}
