<div class="tab-content-wrapper">
  <div class="employee-form-wrapper">
    <div class="form-wrapper">
      <label for="">Annual Pay</label>
      <mat-form-field>
        <input
          matInput
          placeholder="Enter Annual Pay"
          formControlName="branchName"
        />
      </mat-form-field>
    </div>
    <div class="form-wrapper">
      <label for="">Housing Allowance</label>
      <mat-form-field>
        <input
          matInput
          placeholder="Enter Housing Allowance"
          formControlName="branchName"
        />
      </mat-form-field>
    </div>
    <div class="form-wrapper">
      <label for="">Transport Allowance</label>
      <mat-form-field>
        <input
          matInput
          placeholder="Enter Transport Allowance"
          formControlName="branchName"
        />
      </mat-form-field>
    </div>
    <div class="form-wrapper">
      <label for="">Medical Insurance </label>
      <mat-form-field>
        <input
          matInput
          placeholder="Enter Medical Insurance "
          formControlName="branchName"
        />
      </mat-form-field>
    </div>
    <div class="form-wrapper">
      <label for="">Overtime Pay</label>
      <mat-form-field>
        <input
          matInput
          placeholder="Enter Overtime Pay"
          formControlName="branchName"
        />
      </mat-form-field>
    </div>
    <div class="form-wrapper">
      <label for="">Other Allowances</label>
      <mat-form-field>
        <input
          matInput
          placeholder="Enter Other Allowances"
          formControlName="branchName"
        />
      </mat-form-field>
    </div>
  </div>
  <div class="submit-btn-wrapper">
    <button class="cmn-cancel-btn">Cancel</button>
    <button class="cmn-next-btn">Save</button>
  </div>
</div>
