import {
  GET_DESIGNATIONS,
  GET_USER_ROLES,
} from './../graphql/queries/employee.queries';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import {
  GET_BRANCH_NAMES,
  GET_EMPLOYEES,
} from '../graphql/queries/employee.queries';
import { EXPORT_EMPLOYEES } from '../graphql/mutation/employee.mutation';
import { CREATE_EMPLOYEE } from '../graphql/mutation/add-employee.mutation';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  constructor(private http: HttpClient, private apollo: Apollo) {}

  getEmployees(search: string = '', filter: {}): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_EMPLOYEES,
        variables: { search, filter },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => result.data?.getEmployees || []));
  }

  createEmployee(employeeData: any): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: CREATE_EMPLOYEE,
      variables: {
        input: employeeData,
      },
    });
  }

  // Fetch branches
  getBranches(): Observable<any[]> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_BRANCH_NAMES,
      })
      .valueChanges.pipe(map((result) => result?.data?.branches || []));
  }

  // Fetch designations
  getDesignations(search: string = '', status: number = 1): Observable<any[]> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_DESIGNATIONS,
        variables: {
          search: search,
          status: status,
        },
      })
      .valueChanges.pipe(map((result) => result?.data?.designations || []));
  }

  //Fetch user roles
  getUserRoles(search: string = ''): Observable<any[]> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_USER_ROLES,
        variables: {
          search: search,
        },
      })
      .valueChanges.pipe(map((result) => result?.data?.profiles || []));
  }

  //Fetch employee types
  getEmployeeTypes(): Observable<any[]> {
    return this.http.get<any[]>('assets/employee-types.json');
  }

  //exporting employee data
  exportEmployeesToPDF(fields: string[]): Observable<any> {
    return this.apollo
      .use('hrms')
      .mutate<any>({
        mutation: EXPORT_EMPLOYEES,
        variables: {
          fields,
        },
      })
      .pipe(map((result) => result.data?.exportEmployeesToPDF));
  }
}
